<template>
    <div class="container">
        <crud ref="crud" :options="options">
            <template slot="header">
                <el-form-item>
                    <el-input v-model="options.params.keyword" clearable placeholder="抖音账号/昵称"/>
                </el-form-item>
                <el-form-item>
                    <el-button @click="$refs.crud.getLists()" icon="el-icon-search">查找</el-button>
                </el-form-item>
            </template>
        </crud>
    </div>
</template>

<script>
export default {
    name: "List",
    data: function () {
        return {
            options: {
                addBtn: true,
                editBtn: true,
                delBtn: true,
                pager: true,
                addUrl: 'league.MemberProfile.add',
                addPerm: 'league_MemberProfile_add',
                editUrl: 'league.MemberProfile.edit',
                editPerm: 'league_MemberProfile_edit',
                delUrl: 'league.MemberProfile.delete',
                delPerm: 'league_MemberProfile_delete',
                listUrl: 'league.MemberProfile.page',
                formLabelWidth: '100px',
                params: {
                    keyword: null
                },
                columns: [
                    {
                        prop: "member_id", label: "提交用户", formatter: row => {
                            return row.member ? row.member.nickname : ''
                        }
                    },
                    {prop: "account", label: "抖音账号", required: true},
                    {prop: "nickname", label: "抖音昵称", required: true},
                    {
                        prop: "avatar", label: "抖音头像", width: 72, required: true, type: 'image',
                        html: ({row}) => {
                            return row.avatar ? `<img class="list-user-avatar" src="${row.avatar}" alt="加载失败"/>` : null
                        }
                    },
                    {prop: "fans", label: "粉丝数量", type: "number", required: true},
                    {
                        prop: "gender", label: "性别",
                        type: "radio",
                        value: 1,
                        dicData: [{label: '男', value: 1}, {label: '女', value: 2}],
                        formatter: row => {
                            return row.gender === 1 ? '男' : row.gender === 2 ? '女' : '未知'
                        }
                    },
                    {
                        prop: "level_id", label: "带货等级", dicUrl: 'league.MemberLevel.list',
                        type: 'select',
                        dicData: [],
                        dicProps: {label: 'name', value: 'id'},
                        required: true, formatter: row => {
                            return row.lv ? row.lv.name : '-'
                        }
                    },
                    {
                        prop: "level", label: "等级截图", width: 72, type: 'image',
                        html: ({row}) => {
                            return row.level ? `<a href="${row.level}" target="_blank"><img class="list-user-avatar" src="${row.level}" alt="加载失败"/></a>` : null
                        }
                    },
                    {prop: "region", label: "地区", required: true},
                    {
                        prop: "admin_id", label: "审核员", formatter: row => {
                            return row.admin ? row.admin.nickname : ''
                        }
                    },
                    {prop: "calc_time", label: "审核时间",},
                    {prop: "result", label: "审核结果",},
                    {
                        prop: "status", label: "审核状态", formatter: row => {
                            const status = ['待支付', '待审核', '已通过', '已驳回']
                            return status[row.status]
                        }
                    }
                ],
            }
        }
    },
    methods: {}
}
</script>

<style scoped>

</style>
